$pagePadding: 20px;
$innerPadding: 30px;
$fontSizeSmall: 15px;
$fontSizeMedium: 20px;
$fontSizeLarge: 56px;

$grey: #aaa;
$blue: #0387C8;

$Music: #ffae00;
$Music--light: #FFF0D3;

$Art: #0086c8;
$Art--light: #D2E8F4;

$Science: #00ae00;
$Science--light: #D3F0D4;

$Geography: #ff7bac;
$Geography--light: #FFE6EE;

$Vocabulary: #662d91;
$Vocabulary--light: #E3D9EA;

$History: #ff0049;
$History--light: #FFD2DE;

$Film: #000000;
$Film--light: #D2D2D2;

$Math: #869096;
$Math--light: #F2F5F6;

$Bonus: linear-gradient(#e66465, #9198e5);;
$Bonus--light: linear-gradient(#e66465, #9198e5);;

$smallBoxSize: 72px;




@font-face {
	font-family: 'Windsor';
	src: url('/fonts/windsor.otf');
}


html, body {
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
	font-size: $fontSizeSmall;
	background: white;
}


h1,h2,h3,h4,h5,h6, div, ul, li {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	font-family: 'IBM Plex Mono', monospace;
	font-weight: 400;
	font-size: $fontSizeSmall;
}


.archive {
	padding: 20px;
	text-align: center;
	.logo {
		width: 170px;
		margin: 18px 0;
		margin-top: 0;
		height: 42.5px;
	}
	img {
		max-width: 400px;
	}
}

.archive-heading {
	margin: 40px 30px;
}


.archive-body {
	max-width: 400px;
	margin: 30px auto 40px;
}

.archive-links {
	margin: 80px 0;
	margin-bottom: 60px;

	h3 {
		margin-top: 12px;
	}

	.links {
		display: flex;
		justify-content: center;
		margin-top: 12px;

		a {
			margin: 0px 8px;
		}
	}

	img {
		width: 140px;
		height: 171px;
	}


	 a {
	 	color: black;
	 }
}


// LOADING SCREEN


.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #fff;
	height: 100vh;
	width: 100%;
}

.loading-logo {
	position: fixed;
	z-index: 10000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	max-width: 350px;

	transition: opacity .2s ease-out;
}

.loading-logo--hidden {
	opacity: 0;
}


.offscreen-preload {
	font-family: "Windsor";
	font-size: 1px;
	position: absolute;
	top: -9999px;
	left: -9999px;

	&.alt {
		font-family: 'IBM Plex Mono', monospace;
	}
}


.animate-in {
	animation-delay: .04s;
	animation: fade-in-up .6s cubic-bezier(0.19, 1, 0.22, 1);
	animation-fill-mode: forwards;
	opacity: 0;
}

@keyframes fade-in-up {
	from {
		opacity: 0;
		transform: translate3d(0, 48px, 0)
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0px, 0)

	}
}


@media(min-width: 850px) {
	.archive .logo {
		width: 400px;
		height: 100px;
	}
}
